
  var rt = require("@stylable/runtime/cjs/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "StylableButton1872886392",
      {"classes":{"root":"StylableButton1872886392__root","label":"StylableButton1872886392__label","link":"StylableButton1872886392__link","container":"StylableButton1872886392__container","icon":"StylableButton1872886392__icon"},"vars":{},"stVars":{},"keyframes":{}},
      "",
      -1,
      module.id
  );
  